import React, { useEffect, useState, useContext } from "react";
import { api, root } from "../../services/api";
import { idToServerName, storeSteps } from "../../services/util";
import { translate, setLanguageToI18n } from "../../services/i18n";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Dialog } from "primereact/dialog";
import { Steps } from "primereact/steps";
import { ScrollPanel } from "primereact/scrollpanel";

import { ProgressSpinner } from "primereact/progressspinner";
import { clarity } from "react-microsoft-clarity";
import { UserContext } from "../../UserProvider";

import StoreItem from "./store-item/index";

import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import "./styles.scss";
import { Button } from "primereact/button";
import { useParams } from "react-router-dom";

export function Store() {
  clarity.init("h0u79bx1kk");
  clarity.consent();

  const { server } = useParams();

  const { verifiedUsername } = useContext(UserContext);

  const [state, setState] = useState({
    loading: true,
    historic: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      let getParams = localStorage.getItem("startParams");
      if (getParams) {
        if (JSON.parse(getParams).lang) {
          setLanguageToI18n(JSON.parse(getParams).lang);
        } else {
          setLanguageToI18n("en-US");
        }
      } else {
        setLanguageToI18n("en-US");
      }

      loadProducts();
      window.dispatchEvent(
        new CustomEvent("add-route", {
          detail: {
            label: idToServerName(server),
            url: `/${server}`,
            level: 2,
          },
        })
      );
    };

    fetchData();
  }, [server]);

  async function loadProducts() {
    const getParams = JSON.parse(localStorage.getItem("startParams"));

    if (getParams) {
      let user = getParams.username;

      let response = await root.get("/data/config.json");
      let storeInfo = response.data[server];

      let response2 = await api.get(
        `payment/cashdata?id=${user}&server=${server}`
      );
      let storeData = response2.data;
      localStorage.setItem("storeData", JSON.stringify(storeData));
      setState({
        store: server,
        storeInfo: storeInfo,
        storeData: storeData,
        loading: false,
        historic: false,
      });
    }
  }

  function printStoreItem(item) {
    return (
      <div
        className={
          item.status === "normal"
            ? "p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-3"
            : "p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6"
        }
        key={item.sku}
      >
        <StoreItem item={item} server={server}></StoreItem>
      </div>
    );
  }

  function printExclusiveStoreItem(item) {
    return (
      <div className={"p-col-12 p-sm-12 p-md-12 p-lg-6 p-xl-6"} key={item.sku}>
        <StoreItem item={item} server={server}></StoreItem>
      </div>
    );
  }

  function printHistoric(storeData) {
    return (
      <Dialog
        header="Historic"
        visible={state.historic}
        style={{ width: "50vw" }}
        modal={true}
        onHide={() =>
          setState({
            store: state.server,
            storeInfo: state.storeInfo,
            storeData: state.storeData,
            loading: state.loading,
            historic: false,
          })
        }
      >
        <ScrollPanel style={{ width: "100%", height: "500px" }}>
          <Accordion>
            {storeData.historic
              .sort((a, b) => {
                return new Date(b.date) - new Date(a.date);
              })
              .slice(0, 5)
              .map((value, key) => {
                let step = 1;
                if (value.status !== "waiting") step = 3;
                return (
                  <AccordionTab
                    header={`${value.name} - ${value.date}`}
                    key={key}
                  >
                    <br />
                    <b>Name: </b> {value.name} <br />
                    <b>SKU: </b> {value.sku} <br />
                    <b>Gateway: </b> {value.gateway} <br />
                    <b>Last Update: </b> {value.date} <br />
                    <br />
                    <Steps
                      model={[
                        { label: "Started" },
                        { label: "Waiting" },
                        { label: "Processing" },
                        { label: "Delivered" },
                      ]}
                      activeIndex={step}
                      readOnly={true}
                    ></Steps>
                  </AccordionTab>
                );
              })}
          </Accordion>
        </ScrollPanel>
      </Dialog>
    );
  }

  return (
    <>
      {verifiedUsername &&
        (state.loading ? (
          <div className="spinner-div" data-testid="spinner-test">
            <ProgressSpinner />
          </div>
        ) : (
          <div className="store-list">
            {printHistoric(state.storeData)}
            <Button
              className="historicButton"
              label="Ver histórico"
              onClick={() =>
                setState({
                  store: state.server,
                  storeInfo: state.storeInfo,
                  storeData: state.storeData,
                  loading: state.loading,
                  historic: true,
                })
              }
            ></Button>
            <div className="logo-div">
              <img
                src={"../../" + state.storeInfo.img}
                alt={state.storeData.name}
              ></img>
            </div>
            <div className="p-col-12">
              <div className="store-warning">
                {translate("page.store.tip.part1")}
                <a href="https://www.facebook.com/GlobalWYD/" target="_parent">
                  {translate("page.store.tip.partSocial")}
                </a>
                {translate("page.store.tip.part2")}
                <a href="https://feedback.kersef.com/" target="_parent">
                  {translate("page.store.tip.partSystem")}
                </a>
              </div>
            </div>

            <div className="p-grid p-align-center store-items p-justify-center p-mb-5 p-mt-5">
              {state.storeData.exclusiveItemTable.map((item) =>
                printExclusiveStoreItem(item)
              )}
            </div>

            <div className="p-grid p-align-center store-items">
              {state.storeData.itemTable.map((item) => printStoreItem(item))}
            </div>
            {storeSteps(1)}
          </div>
        ))}
    </>
  );
}

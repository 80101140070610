import React from 'react';
import { Steps } from 'primereact/steps';
import { translate } from './i18n';

const getStoresFile = () => {
    if (window.location.host === "store-global.raidhut.com" ||
        window.location.host === "store-wydglobal.raidhut.com") {
        return "/data/stores-wydglobal.json"
    } else if (window.location.host === "store-br.raidhut.com" ||
        window.location.host === "store-wydbr.raidhut.com") {
        return "/data/stores-wydbr.json"
    } else if (window.location.host === "store-test.raidhut.com") {
        return "/data/stores-test.json"
    }
    return "/data/stores.json"
}

const idToServerName = (id) => {
    let serverNames = {
        origem: "Origem",
        odin: "Asgard",
        globalusd: "Global (USD)",
        globalbrl: "Global (BRL)",
        seasonusd: "Loki (USD)",
        seasonbrl: "Loki (BRL)"
    };
    return serverNames[id] ? serverNames[id] : "WYD";
}

const storeSteps = (index) => {
    return (
        <Steps activeIndex={index} model={[{ label: translate('component.steps.step1') }, { label: translate('component.steps.step2') }, { label: translate('component.steps.step3') }, { label: translate('component.steps.step4') }]} />
    );
}

export { idToServerName, storeSteps, getStoresFile };
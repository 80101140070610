export const pt = {
  tag: "pt-BR",
  page: {
    select: {
      title: "Selecione sua loja:",
    },
    status: {
      onMaintence: "Loja em manutenção...",
    },
    store: {
      tip: {
        part1: "Precisando de ajuda? Entre em contato via ",
        partSocial: "Facebook",
        part2: " ou ",
        partSystem: "Suporte",
      },
      item: {
        only: "Apenas",
        days: "dia(s) e",
        buy: "COMPRAR",
        gift: "Brinde(s):",
      },
    },
    payment: {
      confirmationQuestion:
        "Têm certeza que deseja comprar para a conta @Acc e para o servidor @Server?",
      packsQuestion: "Quantos pacotes deseja adquirir?",
      methodQuestion: "Selecione um meio de pagamento:",
      other: "Outro",
      note: "O custo final do pagamento pode mudar de acordo com o método escolhido. Para ver o custo final clique nos botões acima.",
    },
    confirmation: {
      phone: "Número do celular",
      fullname: "Nome completo",
      cep: "CEP",
      cpf: "CPF",
      submit: "Enviar",
    },
  },
  component: {
    header: {
      button: "Voltar",
    },
    steps: {
      step1: "Loja",
      step2: "Pacote",
      step3: "Pagamento",
      step4: "Processamento",
    },
  },
}

export const en = {
  tag: "en-US",
  page: {
    select: {
      title: "Select your store:",
    },
    status: {
      onMaintence: "Store under maintence...",
    },
    store: {
      tip: {
        part1: "Need help with your purchase? Contact us by ",
        partSocial: "Facebook",
        part2: " or ",
        partSystem: "Support",
      },
      item: {
        only: "Only",
        days: "day(s) and",
        buy: "BUY",
        gift: "Gift(s):",
      },
    },
    payment: {
      confirmationQuestion:
        "Are you sure you want to purchase for @Acc account and @Server server?",
      packsQuestion: "How many packs do you want?",
      methodQuestion: "Select the payment method:",
      other: "Other",
      note: "The final cost of the virtual goods may charge rates depending on the payment method selected. To see the final cost, click the button for the payment method you want.",
    },
    confirmation: {
      phone: "Enter phone number",
      fullname: "Enter fullname",
      cep: "Enter CEP",
      cpf: "Enter CPF",
      submit: "Submit",
    },
  },
  component: {
    header: {
      button: "Back",
    },
    steps: {
      step1: "Store",
      step2: "Pack",
      step3: "Payment",
      step4: "Processing",
    },
  },
}

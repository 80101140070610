import React, { Component } from "react";
import { translate } from "../../services/i18n";
import { Link } from "react-router-dom";

import "./styles.scss";

import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

export default class Header extends Component {
  state = {
    items: [{ label: "Select Store", url: "/" }],
    previousPage: "/",
  };

  componentDidMount() {
    window.addEventListener("add-route", this._addRoute);
  }

  componentWillUnmount() {
    window.removeEventListener("add-route", this._addRoute);
  }

  _addRoute = (e) => {
    const { items } = this.state;
    while (e.detail && items.length >= e.detail.level) items.pop();
    let previousPage = "/";
    if (items.length > 0) previousPage = items[Math.max(items.length - 1)].url;
    items.push(e.detail);
    this.setState({ items, previousPage });
  };

  render() {
    const { items } = this.state;
    const { previousPage } = this.state;
    return (
      <div
        className="main-header content-section implementation"
        style={{ backgroundImage: `${process.env.PUBLIC_URL}/img/header.jpg` }}>
        {items.length > 1 && (
          <Link to={previousPage}>{translate("component.header.button")}</Link>
        )}
      </div>
    );
  }
}

import React, { useEffect, useState, useContext } from "react";
import { ProgressSpinner } from "primereact/progressspinner";
import { setLanguageToI18n } from "../../services/i18n";
import StoreItem from "./rigth-column/";
import PaymentInfo from "./left-column/";
import { storeSteps } from "../../services/util";

import { UserContext } from "../../UserProvider";

import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import "./styles.scss";
import { useParams } from "react-router-dom";

export function SelectPayment() {
  const { id, server } = useParams();
  const [loading, setLoading] = useState(true);
  const [packs, setPacks] = useState(1);
  const [storeItem, setStoreItem] = useState({});

  const { verifiedUsername } = useContext(UserContext);

  useEffect(() => {
    let getParams = localStorage.getItem("startParams");
    if (getParams) {
      if (JSON.parse(getParams).lang) {
        setLanguageToI18n(JSON.parse(getParams).lang);
      } else {
        setLanguageToI18n("en-US");
      }
    } else {
      setLanguageToI18n("en-US");
    }

    window.dispatchEvent(
      new CustomEvent("add-route", {
        detail: { label: "Pagamento", url: `/${server}/${id}`, level: 3 },
      })
    );
    let storeData = JSON.parse(localStorage.getItem("storeData"));
    if (
      storeData.itemTable.filter((value) => {
        return value.sku === id;
      })[0] !== undefined
    ) {
      setStoreItem(
        storeData.itemTable.filter((value) => {
          return value.sku === id;
        })[0]
      );
      setLoading(false);
    } else {
      setStoreItem(
        storeData.exclusiveItemTable.filter((value) => {
          return value.sku === id;
        })[0]
      );
      setLoading(false);
    }
  }, [id, server]);

  return (
    <>
      {verifiedUsername &&
        (loading ? (
          <div className="spinner-div" data-testid="spinner-div">
            <ProgressSpinner />
          </div>
        ) : (
          <div>
            <section className="p-grid p-align-center payment-section">
              <div className="p-col left-column">
                <PaymentInfo
                  item={storeItem}
                  server={server}
                  packs={packs}
                  packChangeFunction={setPacks}
                  id={id}
                />
              </div>
              <div className="p-col right-column">
                <StoreItem item={storeItem} server={server} packs={packs} />
              </div>
            </section>
            {storeSteps(2)}
          </div>
        ))}
    </>
  );
}

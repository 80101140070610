import Cookies from "universal-cookie";
import { api } from "./services/api";

const cookies = new Cookies();

async function createCookie(token) {
  cookies.set("auth", token, { path: "/" });
}

async function verifyCookie(username, token) {
  try {
    await api.get(
      `payment/verify-session-token?username=${username}&token=${token}`
    );
    return true;
  } catch (error) {
    console.log("Token verification failed with error:", error);
    return false;
  }
}

export { createCookie, verifyCookie };

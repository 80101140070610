import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { SelectStore } from "./pages/select";
import { Store } from "./pages/store";
import { SelectPayment } from "./pages/payment";
import { LastStep } from "./pages/confirmation";
import Header from "./components/Header";
import { UTMProvider } from "./contexts/UTMContext";
import { UserProvider } from "./UserProvider";
import Error from "./pages/error";

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/404" children={<Error />} />
      <UserProvider>
        <UTMProvider>
          <Header />
          <Switch>
            <Route exact path="/" children={<SelectStore />} />
            <Route exact path="/:server" children={<Store />} />
            <Route exact path="/:server/:id" children={<SelectPayment />} />
            <Route
              exact
              path="/:server/:id/:amount/:gateway"
              children={<LastStep />}
            />
          </Switch>
        </UTMProvider>
      </UserProvider>
    </Switch>
  </BrowserRouter>
);

export default Routes;

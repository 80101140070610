import React, { useEffect, useState, useContext } from "react";
import { root } from "../../services/api";
import { setLanguageToI18n, translate } from "../../services/i18n";
import { Link, useLocation } from "react-router-dom";
import { ProgressSpinner } from "primereact/progressspinner";
import ReactGA from "react-ga";

import { getStoresFile, storeSteps } from "../../services/util";

import { UserContext } from "../../UserProvider";

import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import "./styles.scss";
import SendLog from "../../services/SendLog";

export function SelectStore() {
  const location = useLocation();

  const { verifiedUsername } = useContext(UserContext);

  const [isUnderMaintenance, setIsUnderMaintenance] = useState(false);
  const [state, setState] = useState({
    stores: [],
    storeInfo: {},
    loading: true,
  });

  useEffect(() => {
    checkServiceStatus();
    loadProducts();
    let getParams = localStorage.getItem("startParams");

    if (getParams) {
      if (JSON.parse(getParams).lang) {
        setLanguageToI18n(JSON.parse(getParams).lang);
      } else {
        setLanguageToI18n("en-US");
      }
    } else {
      setLanguageToI18n("en-US");
    }
    SendLog();
  }, []);

  async function checkServiceStatus() {
    try {
      const response = await fetch(
        "https://qhvn7xul7up4cldommobkkop3a0hygca.lambda-url.us-east-1.on.aws/service?service=cash_store"
      );
      if (response.ok) {
        const data = await response.json();
        if (data.status === 0) {
          setIsUnderMaintenance(true);
        }
      }
    } catch (error) {
      if (error.response) {
        console.error("Erro ao obter os dados. ", error.response.status);
      } else {
        console.error("Ocorreu um erro:", error.message);
      }
    }
  }

  async function loadProducts() {
    const queryString = require("query-string");
    const getParams = queryString.parse(location.search);
    if (getParams.username) {
      localStorage.setItem("startParams", JSON.stringify(getParams));
      setLanguageToI18n(getParams.lang);
      ReactGA.initialize("UA-121599966");
      ReactGA.set({
        userId: getParams.username,
      });
    }
    const response1 = await root.get("/data/config.json");
    const { ...storeInfo } = response1.data;

    const response2 = await root.get(getStoresFile());
    const { stores } = response2.data;

    window.dispatchEvent(
      new CustomEvent("add-route", {
        detail: { label: "home", url: `/`, level: 1 },
      })
    );
    setState({
      stores: stores,
      storeInfo: storeInfo,
      loading: false,
    });
  }

  function printStore(store, storeInfo) {
    return (
      <div className="p-col-12 p-sm-12 p-md-6 p-lg-4 p-xl-3" key={store}>
        <Link to={`/${store}`}>
          <div className="store-item">
            <img src={storeInfo[store].img} alt={store}></img>
          </div>
        </Link>
      </div>
    );
  }

  if (isUnderMaintenance) {
    return (
      <div className="maintence-alert">
        <img alt="Under Maintenance" src={"./banner_under_maintence.jpg"} />
      </div>
    );
  }

  return (
    <>
      {verifiedUsername &&
        (state.loading ? (
          <div className="spinner-div">
            <ProgressSpinner />
          </div>
        ) : (
          <div className="store-list">
            <h1>{translate("page.select.title")}</h1>
            <div className="p-grid p-align-center p-justify-center stores">
              {state.stores.map((store) => printStore(store, state.storeInfo))}
            </div>
            {storeSteps(0)}
          </div>
        ))}
    </>
  );
}

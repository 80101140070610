import React, { useState } from "react";

import "./style.scss"


function GiftDescription(props) {
    const [showDescription, setShowDescription] = useState(false)

    function handleMouseEnter() {
        setShowDescription(true)
    }

    function handleMouseLeave() {
        setShowDescription(false)
    }

    return (
        <>
            {props.packs ?
                <p key={props.gift.name} className="detail-gift">
                    {<img src={`https://wydmisc.raidhut.com.br/icons/${(props.server === "globalbrl" || props.server === "globalusd" || props.server === "seasonusd" || props.server === "seasonbrl" || props.server === "test") ? "global" : props.server}/${props.gift.icon}`} alt={props.gift.name} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />}
                    <span>{props.packs > 1 ? props.packs + "x " : ""}{props.gift.name}</span>
                    {(showDescription && props.gift.descriptionData) && (
                        <span className="description-data">
                            {
                                props.gift.descriptionData.map((row, key) => {
                                    if (row[0].length === 8)
                                        row[0] = row[0].slice(0, -2)

                                    return (
                                        <p key={key} style={{ color: "#" + row[0], opacity: row[1] === "" && 0.0 }}>{row[1] === "" ? "." : row[1]}</p>
                                    )
                                })
                            }
                        </span>
                    )}
                </p>

                :
                <p key={props.gift.name} className="gift-icon">
                    {props.gift.icon !== "npextra.png" && <img src={`https://wydmisc.raidhut.com.br/icons/${(props.server === "globalbrl" || props.server === "globalusd" || props.server === "seasonusd" || props.server === "seasonbrl" || props.server === "test") ? "global" : props.server}/${props.gift.icon}`} alt={props.gift.name} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} />}
                    <span>{props.gift.name}</span>
                    {(showDescription && props.gift.descriptionData) && (
                        <span className="description-data">
                            {
                                props.gift.descriptionData.map((row, key) => {
                                    if (row[0].length === 8)
                                        row[0] = row[0].slice(0, -2)

                                    return (
                                        <p key={key} style={{ color: "#" + row[0], opacity: row[1] === "" && 0.0 }}>{row[1] === "" ? "." : row[1]}</p>
                                    )
                                })
                            }
                        </span>
                    )}
                </p>
            }
        </>
    )

}

export default GiftDescription
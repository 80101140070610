import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const UTMContext = createContext("")

export const UTMProvider = ({children}) => {
    const [utm_source, set_utm_source] = useState("")
    const [utm_medium, set_utm_medium] = useState("")
    const [utm_campaign, set_utm_campaign] = useState("")
    
    const location = useLocation()

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        set_utm_source(searchParams.get("utm_source") || "")
        set_utm_medium(searchParams.get("utm_medium") || "")
        set_utm_campaign(searchParams.get("utm_campaign") || "")
    }, [])

    function print(){
        console.log(`utm_source: ${utm_source}`)
        console.log(`utm_medium: ${utm_medium}`)
        console.log(`utm_campaign: ${utm_campaign}`)
    }

    return (
        <UTMContext.Provider value={{utm_source, utm_medium, utm_campaign, print}}>
            {children}
        </UTMContext.Provider>
    )
}
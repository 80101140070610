import React, { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { verifyCookie } from "./jwt";
import queryString from "query-string";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const location = useLocation();
  const getUrlParams = queryString.parse(location.search);
  let getCacheParams = localStorage.getItem("startParams");

  const [isTokenChecked, setIsTokenChecked] = useState(false);
  const [verifiedUsername, setVerifiedUsername] = useState("");
  const [verifiedToken, setVerifiedToken] = useState("");

  useEffect(() => {
    let isTokenValid = true;
    const validateToken = async () => {
      if (getUrlParams.username && !isTokenChecked) {
        isTokenValid = await checkToken(
          getUrlParams.username,
          getUrlParams.token,
          isTokenChecked,
          verifiedToken,
          verifiedUsername
        );
        if (!isTokenValid) {
          window.location.href = "/404";
        } else {
          setVerifiedUsername(getUrlParams.username);
          setVerifiedToken(getUrlParams.token);
        }
        setIsTokenChecked(true);
      } else if (isTokenChecked) {
        isTokenValid = await checkToken(
          JSON.parse(getCacheParams).username,
          JSON.parse(getCacheParams).token,
          isTokenChecked,
          verifiedToken,
          verifiedUsername
        );
        if (!isTokenValid) {
          window.location.href = "/404";
        }
      }
    };
    validateToken();
  });

  return (
    <UserContext.Provider value={{ verifiedUsername }}>
      {children}
    </UserContext.Provider>
  );
};

export const checkToken = async (
  username,
  token,
  isTokenChecked,
  verifiedToken,
  verifiedUsername
) => {
  if (!isTokenChecked) {
    return await verifyCookie(username, token);
  } else {
    return await verifySavedCookie(
      username,
      token,
      verifiedToken,
      verifiedUsername
    );
  }
};

const verifySavedCookie = async (
  username,
  token,
  verifiedToken,
  verifiedUsername
) => {
  if (username == verifiedUsername && token == verifiedToken) {
    return true;
  } else {
    return false;
  }
};

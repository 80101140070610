import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { translate } from '../../../services/i18n';

import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import "primeflex/primeflex.css"

import "./styles.scss";
import GiftDescription from '../../../components/GiftDescription';

export default class StoreItem extends Component {

    state = {
        hover: false,
        remainTimeInSeconds: 1
    }

    componentDidMount() {
        const { item } = this.props;
        if (item.availableuntil) {
            let newDate = new Date(item.availableuntil);
            let today = new Date();
            this.setState({ remainTimeInSeconds: (newDate.getTime() - today.getTime()) / 1000 });
            this.interval = setInterval(() => this.tick(), 1000);
        }
    }

    tick() {
        this.setState({ remainTimeInSeconds: this.state.remainTimeInSeconds - 1 });
    }

    componentWillUnmount() {
        const { item } = this.props;
        if (item.availableuntil) {
            clearInterval(this.interval);
        }
    }

    gap = (number) => {
        return number < 10 ? `0${number}` : number;
    }

    printTimer = (item) => {
        if (item.availableuntil) {
            let seconds = this.state.remainTimeInSeconds;
            let value = "";
            let s = this.gap(Math.floor(seconds % 60));
            let m = this.gap(Math.floor((seconds / 60) % 60));
            let h = this.gap(Math.floor((seconds / 3600) % 24));
            let d = Math.floor(seconds / (3600 * 24));

            value = translate('page.store.item.only') + " " + (d > 0 ? `${d} ${translate('page.store.item.days')} ` : "") + `${h}:${m}:${s}`;

            return (
                <div>
                    <p>{value}</p>
                </div>
            );
        }
        return (<div></div>);
    }

    printGifts = (gifts) => {
        const { server } = this.props;
        return (
            <span>
                {gifts.map((gift, key) =>
                    <GiftDescription key={key} gift={gift} server={server}></GiftDescription>
                )}
            </span>
        );
    }

    printDescription = (item, npOps, npResult) => {
        if (item.NP + item.NPBonus > 0) {
            return <div id="description">
                <p>{item.NPBonus > 0 ? npOps : ""}<br /><b>{npResult}</b></p>
                {item.gifts.length > 0 && <p><br /><b className="gift-title">{translate('page.store.item.gift')}</b></p>}
                <span>{this.printGifts(item.gifts)}</span>
            </div>;
        }
        else {
            return <div id="description">
                <span>{this.printGifts(item.gifts)}</span>
            </div>;
        }
    }

    printDetails = (item, npOps, npResult, currencySymbol) => {
        if (item.NP + item.NPBonus > 0) {
            return <div id="details">
                <p id="title">{item.NPBonus > 0 ? npOps : ""}<br /><b>{npResult}</b></p>
                <div className="details-price">
                    <div><h4 id="price">{currencySymbol}{(item.priceReal * (1 - item.discount)).toLocaleString(item.currencycode === "BRL" ? "pt-BR" : "en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h4></div>
                    {item.discount > 0 && <div><h4 id="striked-price">{currencySymbol}{item.priceReal.toLocaleString(item.currencycode === "BRL" ? "pt-BR" : "en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h4><h4 id="percentage">(-{item.discount * 100}%)</h4></div>}
                </div>
                {item.DiscountBoleto > 0 && <h4>{item.DiscountBoleto}</h4>}
            </div>;
        }
        else {
            return <div id="details">
                <p id="title"><span>{this.printGifts(item.gifts)}</span></p>
                <div className="details-price">
                    <div><h4 id="price">{currencySymbol}{(item.priceReal * (1 - item.discount)).toLocaleString(item.currencycode === "BRL" ? "pt-BR" : "en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h4></div>
                    {item.discount > 0 && <div><h4 id="striked-price">{currencySymbol}{item.priceReal.toLocaleString(item.currencycode === "BRL" ? "pt-BR" : "en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</h4><h4 id="percentage">(-{item.discount * 100}%)</h4></div>}
                </div>
                {item.DiscountBoleto > 0 && <h4>{item.DiscountBoleto}</h4>}
            </div>;
        }
    }

    render() {
        const { item } = this.props;
        let currencySymbol = item.currencycode === "BRL" ? "R$ " : "U$ ";
        let npOps = `${item.NP.toLocaleString(item.currencycode === "BRL" ? "pt-BR" : "en-US")} NP + ${item.NPBonus.toLocaleString(item.currencycode === "BRL" ? "pt-BR" : "en-US")} NP = `;
        let npResult = item.NPBonus ? `${(item.NP + item.NPBonus).toLocaleString(item.currencycode === "BRL" ? "pt-BR" : "en-US")} NP` : `${item.NP.toLocaleString(item.currencycode === "BRL" ? "pt-BR" : "en-US")} NP`;
        return (
            <div className={"pack-inner pack-" + item.status}>
                <div id="mid-panel"></div>
                <div className="img-wrapper">
                    {item.limited && <p id="unique-label"> {item.unique2} </p>}
                    <img src={"https://wydmisc.raidhut.com/packs/" + item.icon} alt={item.name}></img>
                    {this.printTimer(item)}
                </div>
                {item.leftTag && item.leftTag.length > 0 && <div className="tag"><h2>{item.leftTag}</h2></div>}
                {item.OptionalTag && item.OptionalTag.length > 0 && <div className="tag tag-right"><h2>{item.OptionalTag}</h2></div>}
                {this.printDescription(item, npOps, npResult)}
                {this.printDetails(item, npOps, npResult, currencySymbol)}

                <Link to={`${this.props.server}/${item.sku}`}>
                    <Button id="button" label={translate('page.store.item.buy')} className="buy-button"></Button>
                </Link>
            </div>
        );
    }
}
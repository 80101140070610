import React from "react";

const Error = () => {
  return (
    <div style={wrapper}>
      <div style={i404}></div>
    </div>
  );
};

const bg_404 = "/bg_404.jpg";
const error_404 = "/404.png";

const wrapper = {
  backgroundImage: `url(${bg_404})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  width: "100%",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-end",
};

const i404 = {
  width: "500px",
  backgroundImage: `url(${error_404})`,
  backgroundSize: "contain",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  height: "150px",
  marginBottom: "5rem",
};

export default Error;
